export default {
  "Forgot your password?": "Vous avez oublié votre mot de passe ?",
  Username: "Nom d'utilisateur",
  Password: "Mot de passe",
  Login: "Enregistrer",
  "Unable to login! Please check your password or username":
    "Erreur de connexion ! Veuillez vérifier votre nom d'utilisateur et votre mot de passe",
  "Choose an account": "Sélectionnez un compte",
  Welcome: "Bienvenue",
  Hours: "Heures",
  Badge: "Badge",
  Overview: "Aperçu",
  Home: "Home",
  Settings: "Paramètres",
  Week: "semaine",
  "You don't have an internet connection at the moment. Please reconnect to get the latest data.":
    "Vous n'avez actuellement pas de connexion internet. Veuillez vous reconnecter à Internet pour récupérer les dernières données.",
  Total: "Totale",
  Date: "Date",
  Break: "Pause",
  "There are no trackings for the selected date.":
    "Il n'y a pas d'enregistrement pour la date sélectionnez",
  Projectinfo: "Info projet",
  "You are viewing a task that is not planned for today.":
    "Vous regardez une tâche qui n'est pas prévue pour aujourd'hui.",
  "You cannot change the task anymore because it is already accepted or your internet connection is down.":
    "Vous ne pouvez plus modifier la tâche parce qu'elle a déjà été acceptée ou parce que vous n'avez pas de connexion internet.",
  Employer: "Employé",
  Client: "Client",
  Task: "Tâche",
  Location: "Location",
  Project: "Projet",
  Manager: "Manager",
  Timeregistration: "Enregistrement des temps",
  "You do not have any timeregistrations for selected date!":
    "Vous n'avez pas encore d'heures de bain pour la date sélectionnée!",
  Checkin: "Checkin",
  Checkout: "Checkout",
  "Some values are invalid or incomplete":
    "Certaines données sont incorrectes ou incomplètes",
  "Saving...": "EN cours de sauvegarde...",
  Save: "Enregistrer",
  "You cannot create tasks because you do not have a internet connection.":
    "Vous ne pouvez pas créer de tâches parce que vous n'avez pas de connexion internet.",
  "Select a task and add a timetracking":
    "Sélectionnez une tâche et ajoutez un horodatage",
  "Select...": "Sélectionnez...",
  None: "Aucun",
  "Timeregistration succesfully saved":
    "Enregistrement des temps sauvegardé avec succès",
  "There are no tasks for selected date":
    "Il n'y a pas encore de tâches pour la date sélectionnée",
  Notifications: "Notifications",
  "Received notifications": "Recevez des notifications",
  "Please accept notifications in your browser to receive them.":
    "Veuillez activer les notifications dans votre navigateur pour recevoir des notifications.",
  "Your device does not support notifications.":
    "Votre appareil n'est pas compatible avec les notifications.",
  Planner: "Planificateur",
  Logout: "déconnexion",
  Account: "Compte",
  Dutch: "Néerlandais",
  French: "Français",
  English: "Anglais",
  Registration: "Registration",
  "Start time": "Heure de début",
  "End time": "Heure de fin",
  "End time needs to be bigger than start time!":
    "L'heure de fin doit être plus grande que l'heure de début !",
  "Total break time": "Durée totale de la Pause",
  Achieved: "Réalisé",
  "This timetracking overlaps with another timetracking!":
    "Ce chronométrage chevauche un autre chronométrage !",
  "This timetracking is not within the badge times!":
    "L'enregistrement du temps n'est pas dans les temps du badge!",
  Checklist: "Liste de contrôle",
  "Use equipments": "Gebruik machines",
  "Add equipment": "Machine toevoegen",
  "Use articles": "Utilisation d'articles",
  "Add article": "Ajouter un article",
  "Anything to report?": "Quelque chose à signaler?",
  "Finished?": "Fini?",
  time: "temps",
  days: "jours",
  "Remove registration": "Supprimer l'enregistrement",
  "Are you sure you want to remove this registration?":
    "Êtes-vous sûr de vouloir supprimer cet enregistrement?",
  Cancel: "Annuler",
  Delete: "Supprimer",
  "Search...": "Recherche...",
  "Together with": "Avec",
  "Project location": "Site du projet",
  "Bestanden": "Fichiers",
};
