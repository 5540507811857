export default {
  toast: null,
  options: {},
  defaults: {
    text: "",
    duration: 4000,
  },
  create: function (options) {
    options = Object.assign(this.defaults, options);
    this.options = options;

    this.toast = document.createElement("div");
    this.toast.className = "toast";
    this.toast.innerHTML =
      '<div class="toast__info">' + options.text + "</div>";
    if (options.action) {
      var button = document.createElement("button");
      button.className = "toast__action-button";
      button.innerHTML = options.action.text;
      button.addEventListener("click", options.action.click);
      var actions = document.createElement("div");
      actions.className = "toast__action";
      actions.appendChild(button);
      this.toast.appendChild(actions);
    }

    return this;
  },
  show: function () {
    var self = this;
    document.body.appendChild(this.toast);
    var toast = this.toast;
    setTimeout(function () {
      toast.className += " show";

      setTimeout(function () {
        toast.className += " hide";
        toast.className = toast.className.replace("show", "");

        setTimeout(() => {
          self.destroy();
        }, 500);
      }, self.options.duration);
    }, 500);
  },
  destroy: function () {
    document.body.removeChild(this.toast);
  },
};
