import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import fr from "./fr";
import nl from "./nl";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "nl",
  fallbackLocale: "en",
  messages: {
    en,
    fr,
    nl,
  },
});
