import api from "./api";
import toast from "./toast";

export default {
  prompt: null,
  version: "2.43",
  toast: null,
  supportsSync: false,
  languages: {
    en: {
      label: "English",
      momentjs: "en-gb",
    },
    fr: {
      label: "French",
      momentjs: "fr",
    },
    nl: {
      label: "Dutch",
      momentjs: "nl-be",
    },
  },
  set: function (key, value) {
    localStorage[key] = JSON.stringify(value);
  },
  get: function (key) {
    if (typeof localStorage[key] === "undefined") {
      return null;
    } else {
      return JSON.parse(localStorage[key]);
    }
  },
  clear: function (key) {
    localStorage.removeItem(key);
  },
  getUsername: function () {
    var self = this;
    var account = this.get("account");
    var username = "";
    if (!account.user) {
      return api.getAccountDetails(account.id).then((data) => {
        self.set("account", data);

        if (data.user.first_name != null) {
          username += data.user.first_name;
        }
        if (data.user.last_name != null) {
          if (username != "") {
            username += " ";
          }
          username += data.user.last_name;
        }
        return username;
      });
    } else {
      return new Promise(function (resolve, reject) {
        if (account.user.first_name != null) {
          username += account.user.first_name;
        }
        if (account.user.last_name != null) {
          if (username != "") {
            username += " ";
          }
          username += account.user.last_name;
        }

        if (username != "") {
          resolve(username);
        } else {
          reject("No username set");
        }
      });
    }
  },
  checkOnline: function (callback) {
    var _listener = function () {
      callback(navigator.onLine);
    };

    window.removeEventListener("online", _listener);
    window.removeEventListener("offline", _listener);
    window.addEventListener("online", _listener);
    window.addEventListener("offline", _listener);
    callback(navigator.onLine);
  },
  checkUpdate: function () {
    api.getVersion().then((data) => {
      if (data.version && data.version != this.version) {
        if (this.toast != null) {
          this.toast.destroy();
        }

        this.toast = toast.create({
          text: "Er is een update beschikbaar",
          action: {
            text: "Update",
            click: function () {
              window.location.reload();
            },
          },
          duration: 10000,
        });

        this.toast.show();
      }
    });
  },
};
