import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/index.html",
      name: "home-html",
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Login.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "/accounts",
      name: "accounts",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Accounts.vue"),
      meta: {
        no_account: true,
      },
    },
    {
      path: "/overview",
      name: "overview",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Overview.vue"),
    },
    {
      path: "/overview-day",
      name: "overview_day",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/OverviewDay.vue"),
    },
    {
      path: "/week",
      name: "week",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Week.vue"),
    },
    {
      path: "/day",
      name: "day",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Day.vue"),
    },
    {
      path: "/task",
      name: "task",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Task.vue"),
    },
    {
      path: "/task/new",
      name: "tasknew",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/TaskNew.vue"),
    },
    {
      path: "/task/saved",
      name: "tasksaved",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/TaskSaved.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Settings.vue"),
    },
    {
      path: "/settings/notifications",
      name: "settingsNotifications",
      component: () => import("./views/settings/Notifications.vue"),
    },
    {
      path: "/badge",
      name: "badge",
      component: () => import("./views/settings/QRCode.vue"),
    },
  ],
});
