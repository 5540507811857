import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";
import Vue from "vue";
import i18n from "./lang/config.js";
import App from "./App.vue";
import router from "./router";
import config from "./prixo/config.js";
import api from "./prixo/api.js";
import helper from "./prixo/helper.js";
import database from "./prixo/database.js";
import webpush from "./prixo/webpush.js";
import pwa from "./prixo/pwa.js";

if (process.env.NODE_ENV === 'production') {
    flare.light('LI3VUgoqinNo3ECrlzhjV6XCqnbu8e5H');
}
Vue.use(flareVue);

import "./registerServiceWorker";

const moment = require("moment");
require("moment-duration-format/lib/moment-duration-format");
require("moment/locale/nl-be");
require("moment/locale/en-gb");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});


database.create();

Vue.prototype.$config = config;
Vue.prototype.$api = api;
Vue.prototype.$helper = helper;
Vue.prototype.$database = database;
Vue.prototype.$webpush = webpush;
Vue.prototype.$pwa = pwa;

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let account = config.get("account");

  if (to.matched.some((record) => record.meta.guest)) {
    next();
  } else if (to.matched.some((record) => record.meta.no_account)) {
    if (config.get("jwt") == null) {
      config.clear("jwt");
      config.clear("account");
      config.clear("accounts");
      next({
        name: "login",
      });
      return;
    }

    next();
  } else {
    if (
      config.get("jwt") == null ||
      !account ||
      (account && isNaN(account.id))
    ) {
      config.clear("jwt");
      config.clear("account");
      config.clear("accounts");
      router.replace({
        name: "login",
      });
      next({
        name: "login",
      });
      return;
    }

    next();
  }

  if (account && account.user) {
    flare.addContext('user_id', account.user.id);
    flare.addContext('user_email', account.user.email);

    moment.locale(config.languages[account.user.language].momentjs);
    i18n.locale = account.user.language;
  }
});

api
  .checkSignedIn()
  .then((response) => {
    if (!response.ok) {
      return new Error("not alive");
    }

    if (config.get("account") != null && !isNaN(config.get("account").id)) {
      api.getAccountDetails(config.get("account").id).then((data) => {
        if (!isNaN(data.id)) {
          config.set("account", data);
        }
      });
    }
  })
  .catch(() => {
    config.clear("jwt");
    config.clear("account");
    router.replace({
      name: "login",
    });
  });

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");

config.supportsSync = "serviceWorker" in navigator && "SyncManager" in window;

config.checkUpdate();

window.addEventListener("beforeinstallprompt", function (event) {
  var deferredPrompt;
  event.preventDefault();
  deferredPrompt = event;
  config.prompt = deferredPrompt;

  return false;
});
