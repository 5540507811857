<template>
  <main class="view view--home">
    <img class="logo" src="img/logo.png" alt="" width="130" />
    <div class="alert alert-primary alert-home">
      {{ $t("Welcome") }} <strong>{{ username }}</strong>
    </div>
    <div class="icon-grid">
      <div class="icon-grid__item">
        <router-link to="/week" class="icon">
          <div class="icon__ball">
            <img src="img/icon-clock.png" alt="" />

            <span v-if="expired_slots > 0" class="badge">{{
              expired_slots
            }}</span>
          </div>
          <div class="icon__text">{{ $t("Hours") }}</div>
        </router-link>
      </div>

      <div
        class="icon-grid__item"
        v-if="$config.get('account') && $config.get('account').badge"
      >
        <router-link :to="{ name: 'badge' }" class="icon">
          <div class="icon__ball">
            <img src="img/icon-qrcode.png" alt="" />
          </div>
          <div class="icon__text">{{ $t("Badge") }}</div>
        </router-link>
      </div>

      <!--             
			<div class="icon-grid__item">
				<a class="icon icon--disabled" href="#">
					<div class="icon__ball">
						<img src="img/icon-palm.png" alt="">
					</div>
					<div class="icon__text">Verlof</div>
				</a>
			</div>

			<div class="icon-grid__item">
				<a class="icon icon--disabled" href="#">
					<div class="icon__ball">
						<img src="img/icon-sick.png" alt="">
					</div>
					<div class="icon__text">Ziekte</div>
				</a>
			</div> -->

      <div class="icon-grid__item">
        <router-link :to="{ name: 'overview' }" class="icon">
          <div class="icon__ball">
            <img src="img/icon-graph.png" alt="" />
          </div>
          <div class="icon__text">{{ $t("Overview") }}</div>
        </router-link>
      </div>

      <!-- 
			<div class="icon-grid__item">
				<a class="icon icon--disabled" href="#">
					<div class="icon__ball">
						<img src="img/icon-text.png" alt="">
					</div>
					<div class="icon__text">Berichten</div>
				</a>
			</div>

			<div class="icon-grid__item">
				<a class="icon icon--disabled" href="#">
					<div class="icon__ball">
						<img src="img/icon-contacts.png" alt="">
					</div>
					<div class="icon__text">Contacten</div>
				</a>
			</div> -->
    </div>
  </main>
</template>

<script>
export default {
  name: "home",
  mounted: function () {
    if (!this.$config.get("account")) {
      this.$router.replace({ name: "login" });
      return false;
    }

    this.$api.getExpiredSlots(this.$config.get("account").id).then((data) => {
      if (data.expired_slots) {
        this.expired_slots = data.expired_slots;
      }
    });
    this.$api.getEquipment(this.$config.get("account").id).then((data) => {
      var self = this;
      this.$database.clearAllData("equipment").then(function () {
        self.$database.writeMultiple("equipment", data);
      });
    });
    this.$api.getArticles(this.$config.get("account").id).then((data) => {
      var self = this;
      this.$database.clearAllData("articles").then(function () {
        self.$database.writeMultiple("articles", data);
      });
    });
    this.$helper.promptHomescreen();

    this.$config.getUsername().then((username) => {
      this.username = username;
    });
  },
  data: function () {
    return {
      expired_slots: 0,
      username: "",
    };
  },
};
</script>
