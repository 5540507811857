export default {
  "Forgot your password?": "Uw wachtwoord vergeten?",
  Username: "Gebruikersnaam",
  Password: "Wachtwoord",
  Login: "Aanmelden",
  "Unable to login! Please check your password or username":
    "Fout bij inloggen! Gelieve uw gebruikersnaam en wachtwoord te controleren",
  "Choose an account": "Selecteer een account",
  Welcome: "Welkom",
  Hours: "Uren",
  Badge: "Badge",
  Overview: "Overzicht",
  Home: "Home",
  Settings: "Instellingen",
  Week: "Week",
  'You don"t have an internet connection at the moment. Please reconnect to get the latest data.':
    "U heeft momenteel geen internetverbinding. Maak terug verbinding met het internet om de laatste data op te halen.",
  Total: "Totaal",
  Date: "Datum",
  Break: "Pauze",
  "There are no trackings for the selected date.":
    "Er zijn geen trackings voor de gekozen datum.",
  Projectinfo: "Projectinfo",
  "You are viewing a task that is not planned for today.":
    "U bekijkt een taak die niet op vandaag gepland is.",
  "You cannot change the task anymore because it is already accepted or your internet connection is down.":
    "U kunt de taak niet meer aanpassen doordat deze reeds is geaccepteerd of omdat u geen internetverbinding heeft.",
  Employer: "Werknemer",
  Client: "Klant",
  Task: "Taak",
  Location: "Locatie",
  Project: "Project",
  Manager: "Manager",
  Timeregistration: "Tijdsregistratie",
  "You do not have any timeregistrations for selected date!":
    "U heeft nog geen badgetijden voor de gekozen datum!",
  Checkin: "Checkin",
  Checkout: "Checkout",
  "Some values are invalid or incomplete":
    "Sommige gegevens zijn verkeerd of onvolledig",
  "Saving...": "Bezig met opslaan...",
  Save: "Opslaan",
  "You cannot create tasks because you do not have a internet connection.":
    "U kunt geen taken aanmaken omdat u geen internetverbinding heeft.",
  "Select a task and add a timetracking":
    "Selecteer een taak en voeg een tijdsregistratie toe",
  "Select...": "Selecteer...",
  None: "Geen",
  "Timeregistration succesfully saved": "Tijdsregistratie succesvol opgeslaan",
  "There are no tasks for selected date":
    "Er zijn nog geen taken voor de gekozen datum",
  Notifications: "Notificaties",
  "Received notifications": "Notificaties ontvangen",
  "Please accept notifications in your browser to receive them.":
    "Gelieve notificaties toe te staan in uw browser om notificaties te ontvangen.",
  "Your device does not support notifications.":
    "Uw toestel is niet compatibel met notificaties.",
  Planner: "Planner",
  Logout: "Afmelden",
  Account: "Account",
  Dutch: "Nederlands",
  French: "Frans",
  English: "Engels",
  Registration: "Registratie",
  "Start time": "Starttijd",
  "End time": "Eindtijd",
  "End time needs to be bigger than start time!":
    "Eindtijd moet groter zijn dan starttijd!",
  "Total break time": "Totaal pauze",
  Achieved: "Gepresteerd",
  "This timetracking overlaps with another timetracking!":
    "Deze tijdsregistratie overlapt met een andere tijdsregistratie!",
  "This timetracking is not within the badge times!":
    "De tijdsregistraties is niet binnen de badge-tijden!",
  Checklist: "Checklist",
  "Use equipments": "Gebruik machines",
  "Add equipment": "Machine toevoegen",
  "Use articles": "Gebruik artikelen",
  "Add article": "Artikel toevoegen",
  "Anything to report?": "Iets te melden?",
  "Finished?": "Afgewerkt?",
  time: "tijd",
  days: "dagen",
  "Remove registration": "Registratie verwijderen",
  "Are you sure you want to remove this registration?":
    "Weet u zeker dat u deze registratie wilt verwijderen?",
  Cancel: "Annuleren",
  Delete: "Verwijderen",
  "Search...": "Zoeken...",
  "Together with": "Samen met",
  "Project location": "Projectlocatie",
  "Bestanden": "Bestanden",
};
