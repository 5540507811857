import database from "./database.js";
import helper from "./helper.js";

const moment = require("moment");
require("moment/locale/nl");

export default {
  get API_URL() {
    return process.env.VUE_APP_API_URL;
  },
  get requestHeaders() {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if ( localStorage["jwt"] ) {
      headers.Authorization =
        "Bearer " + JSON.parse(localStorage["jwt"]);
    }

    return headers;
  },
  getVersion: function () {
    return fetch(this.API_URL + "/version", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    })
      .then((data) => {
        return data.json();
      })
      .catch(function () {
        return false;
      });
  },
  checkSignedIn: function () {
    return fetch(this.API_URL + "/alive", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    });
  },
  getAccounts: function () {
    return fetch(this.API_URL + "/me/accounts", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not load accounts list";
    });
  },
  getAccountDetails: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id, {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get account details";
    });
  },
  getExpiredSlots: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id + "/slots/expired", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get account details";
    });
  },
  getTasks: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id + "/tasks", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get account details";
    });
  },
  getTrackings: function (id, startdate, enddate) {
    return fetch(
      this.API_URL +
        "/me/accounts/" +
        id +
        "/timetrackings?startdate=" +
        startdate +
        "&enddate=" +
        enddate,
      {
        method: "GET",
        headers: this.requestHeaders,
        credentials: "include",
      }
    )
      .then((data) => {
        if (data.status == 200) {
          return data.json();
        }
        throw "Could not get timetrackings";
      })
      .then((timetrackings) => {
        return timetrackings;
      });
  },
  getSlots: function (id, startdate, enddate) {
    return fetch(
      this.API_URL +
        "/me/accounts/" +
        id +
        "/slots?startdate=" +
        startdate +
        "&enddate=" +
        enddate,
      {
        method: "GET",
        headers: this.requestHeaders,
        credentials: "include",
      }
    )
      .then((data) => {
        if (data.status == 200) {
          return data.json();
        }
        throw "Could not get slots";
      })
      .then((slots) => {
        let keep_slots = slots.map(function (value) {
          return value["id"];
        });
        database.readAllData("slots").then((data) => {
          for (var i = 0; i < data.length; i++) {
            let slot = data[i];
            let slot_date = moment(slot.date);
            if (
              slot_date >= moment(startdate) &&
              slot_date <= moment(enddate)
            ) {
              if (!helper.inArray(slot.id, keep_slots)) {
                database.deleteItemFromData("slots", slot.id);
              }
            }
          }
        });
        database.writeMultiple("slots", slots);

        return slots;
      });
  },
  getSlot: function (id, slot_id) {
    if (isNaN(slot_id)) {
      throw "Could not get slots";
    }
    return fetch(this.API_URL + "/me/accounts/" + id + "/slots/" + slot_id, {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get slots";
    });
  },
  getBadgingTimes: function (id, date) {
    return fetch(
      this.API_URL + "/me/accounts/" + id + "/badging_times?date=" + date,
      {
        method: "GET",
        headers: this.requestHeaders,
        credentials: "include",
      }
    ).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get badgings";
    });
  },
  getEquipment: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id + "/equipment", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get equipment";
    });
  },
  getArticles: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id + "/articles", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get articles";
    });
  },
  getSettings: function (id) {
    return fetch(this.API_URL + "/me/accounts/" + id + "/settings", {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    }).then((data) => {
      if (data.status == 200) {
        return data.json();
      }
      throw "Could not get settings";
    });
  },
  postTracking: function (id, data, method) {
    method = method ? method : "POST";
    return fetch(
      this.API_URL +
        "/me/accounts/" +
        id +
        "/tracking" +
        (method == "PUT" ? "/" + data.id : ""),
      {
        method: method,
        headers: this.requestHeaders,
        body: JSON.stringify(data),
        credentials: "include",
      }
    ).then((response) => {
      if (response.status == 200) {
        return response.json();
      }
    });
  },
  saveTracking: function (id, data) {
    return this.postTracking(id, data, "PUT");
  },
  saveUser: function (data) {
    return fetch(this.API_URL + "/me/" + data.id, {
      method: "PUT",
      headers: this.requestHeaders,
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      if (response.status == 200) {
        return response.json();
      }
    });
  },
  downloadFile: async function(accountId, fileId, fileName) {
    const response = await fetch(this.API_URL + "/me/accounts/" + accountId + "/files/download/" + fileId, {
      method: "GET",
      headers: this.requestHeaders,
      credentials: "include",
    });

    if (response.status === 200) {
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link); // Required for Firefox
      link.click();
      document.body.removeChild(link); // Required for Firefox
      return "Download successful";
    } else {
      throw new Error("File download failed");
    }
  }
};
