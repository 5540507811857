import api from "./api.js";

export default {
  applicationServerKey:
    "BAdKpWg3RDeqBzSGjly8yFO4gg9Azs/nq2vWHjOTuEsW55bDdoFGps0s+/UGtFchjDz2A4xE4QP9+vvSC/ZIHzc=",
  pushCompatible: function () {
    if (!("serviceWorker" in navigator)) {
      console.warn("Service workers are not supported by this browser");
      return false;
    }

    if (!("PushManager" in window)) {
      console.warn("Push notifications are not supported by this browser");
      return false;
    }

    if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
      console.warn("Notifications are not supported by this browser");
      return false;
    }

    // Check the current Notification permission.
    // If its denied, the button should appears as such, until the user changes the permission manually
    if (Notification.permission === "denied") {
      console.warn("Notifications are denied by the user");
      return false;
    }

    return true;
  },
  urlBase64ToUint8Array: function (base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
  checkNotificationPermission: function () {
    return new Promise((resolve, reject) => {
      if (Notification.permission === "denied") {
        return reject(new Error("Push messages are blocked."));
      }

      if (Notification.permission === "granted") {
        return resolve();
      }

      if (Notification.permission === "default") {
        return Notification.requestPermission().then((result) => {
          if (result !== "granted") {
            reject(new Error("Bad permission result"));
          }

          resolve();
        });
      }
    });
  },
  subscribe: function () {
    return this.checkNotificationPermission()
      .then(() => navigator.serviceWorker.ready)
      .then((serviceWorkerRegistration) =>
        serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(
            this.applicationServerKey
          ),
        })
      )
      .then((subscription) => {
        // Subscription was successful
        // create subscription on your server
        return this.sendSubscriptionToServer(subscription, "POST");
      })
      .then((subscription) => {
        console.log(subscription);
      });
  },
  updateSubscription: function () {
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) =>
        serviceWorkerRegistration.pushManager.getSubscription()
      )
      .then((subscription) => {
        if (!subscription) {
          // We aren't subscribed to push, so set UI to allow the user to enable push
          return;
        }

        // Keep your server in sync with the latest endpoint
        return this.sendSubscriptionToServer(subscription, "PUT");
      })
      .then((subscription) => {
        console.log(subscription);
      }) // Set your UI to show they have subscribed for push messages
      .catch((e) => {
        console.error("Error when updating the subscription", e);
      });
  },
  unsubscribe: function () {
    // To unsubscribe from push messaging, you need to get the subscription object
    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration) =>
        serviceWorkerRegistration.pushManager.getSubscription()
      )
      .then((subscription) => {
        // Check that we have a subscription to unsubscribe
        if (!subscription) {
          // No subscription object, so set the state
          // to allow the user to subscribe to push
          return;
        }

        // We have a subscription, unsubscribe
        // Remove push subscription from server
        return this.sendSubscriptionToServer(subscription, "DELETE");
      })
      .then((subscription) => subscription.unsubscribe())
      .then(() => {
        console.log("unsubscribed!");
      })
      .catch((e) => {
        // We failed to unsubscribe, this can lead to
        // an unusual state, so  it may be best to remove
        // the users data from your data store and
        // inform the user that you have done so
        console.error("Error when unsubscribing the user", e);
      });
  },
  sendSubscriptionToServer: function (subscription, method) {
    const key = subscription.getKey("p256dh");
    const token = subscription.getKey("auth");
    const contentEncoding = (PushManager.supportedContentEncodings || [
      "aesgcm",
    ])[0];

    return fetch(api.API_URL + "/push/subscribe", {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        endpoint: subscription.endpoint,
        publicKey: key
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
          : null,
        authToken: token
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
          : null,
        contentEncoding,
      }),
    }).then(() => subscription);
  },
};
