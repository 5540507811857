import database from "./database.js";
import api from "./api.js";
import config from "./config.js";

export default {
  getSettings: function () {
    if (config.get("account")) {
      return api
        .getSettings(config.get("account").id)
        .then((data) => {
          database.writeMultiple("settings", data);
          return this.formatSettings(data);
        })
        .catch((err) => {
          console.log(err);
          return database.readAllData("settings").then((data) => {
            return this.formatSettings(data);
          });
        });
    } else {
      return false;
    }
  },
  formatSettings: function (data) {
    var settings = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      settings[element.name] = element.value;
    }

    return settings;
  },
};
