import config from "./config.js";

export default {
  datesBetween: function (startDate, endDate) {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  },
  ucfirst: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  pad: function (num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  },
  promptHomescreen: function () {
    if (config.prompt) {
      let deferredPrompt = config.prompt;
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then(function (choiceResult) {
        if (choiceResult.outcome === "dismissed") {
          console.log("User cancelled installation");
        } else {
          console.log("User added to home screen");
        }
      });

      deferredPrompt = null;
    } else {
      console.log("Geen prompt mogelijk");
    }
  },
  inArray: function (needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  },
};
