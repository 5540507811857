import { openDb } from "idb";

export default {
  dbPromise: null,
  version: 4,
  create: function () {
    return (this.dbPromise = openDb(
      "prixo-store",
      this.version,
      (upgradeDB) => {
        if (upgradeDB.oldVersion < 1) {
          upgradeDB.createObjectStore("slots", {
            keyPath: "id",
          });
          upgradeDB.createObjectStore("equipment", {
            keyPath: "id",
          });
          upgradeDB.createObjectStore("tracking-sync", {
            keyPath: "id",
          });
        }
        if (upgradeDB.oldVersion < 2) {
          upgradeDB.createObjectStore("settings", {
            keyPath: "id",
          });
        }
        if (upgradeDB.oldVersion < 3) {
          upgradeDB.deleteObjectStore("settings");
          upgradeDB.createObjectStore("settings", {
            keyPath: "name",
          });
        }
        if (upgradeDB.oldVersion < 4) {
          upgradeDB.createObjectStore("articles", {
            keyPath: "id",
          });
        }
      }
    ));
  },
  writeData: function (st, data) {
    return this.dbPromise.then(function (db) {
      var tx = db.transaction(st, "readwrite");
      var store = tx.objectStore(st);
      store.put(data);
      return tx.complete;
    });
  },
  writeMultiple: function (st, data) {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      this.writeData(st, element);
    }
  },
  readAllData: function (st) {
    return this.dbPromise.then(function (db) {
      var tx = db.transaction(st, "readonly");
      var store = tx.objectStore(st);
      return store.getAll();
    });
  },
  getDataById: function (st, id) {
    return this.dbPromise.then(function (db) {
      var tx = db.transaction(st, "readonly");
      var store = tx.objectStore(st);
      return store.get(id);
    });
  },
  clearAllData: function (st) {
    return this.dbPromise.then(function (db) {
      var tx = db.transaction(st, "readwrite");
      var store = tx.objectStore(st);
      store.clear();
      return tx.complete;
    });
  },
  deleteItemFromData: function (st, id) {
    this.dbPromise
      .then(function (db) {
        var tx = db.transaction(st, "readwrite");
        var store = tx.objectStore(st);
        store.delete(id);
        return tx.complete;
      })
      .then(function () {
        console.log("Item deleted!");
      });
  },
};
